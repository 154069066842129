import $ from 'jquery'
import { getcsv } from "./getcsv";
import { save } from "./save";
import {smileyanim} from "./floatingSmiley";
import { jumpto, pushnext, pushprev } from "./slide";
import { entityelements } from "./entityelements";

import "jquery-validation";
import { inactivityTime } from './inactivity';
import { overallsave } from './overallsave';
import { savetomongo, savetomongooverall } from './ajax';
import { thankyousave } from './thanyousave';

$.validator.addMethod("phone", function (phone_number, element) {
    phone_number = phone_number.replace(/\s+/g, "");
    return this.optional(element) || phone_number.length === 10 &&
        phone_number.match(/\d{10}$/);
}, "Enter a valid number <span class='ar-'>أدخل رقمًا صالحًا</span>");

$.validator.messages.required = "This field is required. <span class='ar-'>هذه الخانة مطلوبه.</span>";
$("form").validate({ errorElement: "span", errorClass:"invalid", rules: {
    email: {
        email: true
    },
    phone: {
        phone: true
    }
},
messages: {
    email: {
        email: "Enter a valid Email <span class='ar-'>أدخل بريد إلكتروني متاح</span>"
    }
}
});

let downloadcsv
let reloadtime

smileyanim()
getcsv()
entityelements()

window.addEventListener("online",function(){
    savetomongo();
    savetomongooverall();
})

$(".backbtn").on('click',function(){
    pushprev()
})
$(".nextbtn").on('click',function(){
    pushnext()
})
$(".overallback").on('click',function(){
    jumpto(0)
})
$(".feedbacksubmit").on('click',function(){
    $("form[name='feedback-form']").trigger('submit')
})

$(".progress-wrap ul").each(function(){
    $(this).css('--selection-width','0px')
    const target = $(`#${$(this).data('for')}`)
    $(this).children().on('click',function(){
        target.val($(this).data('score'))
        $(this).parent().find('li').removeClass('active')
        $(this).addClass('active')
        // console.log($(this).position().left)
        $(this).parent().css('--selection-width',`${$(this).position().left}px`)
        $(this).prevAll().addClass('active')
    })
})

$("form[name='feedback-form']").on('submit',function(){
    if($(this).valid()){
        const formSerializeArr = $(this).serializeArray()
        const data = {};
        $.map( formSerializeArr, function( n ) {
            data[n.name] = n.value;
        });
        const uid = save(data)
        $(".smile-wrap svg").attr("data-uid",uid)
        $(".smile-wrap svg").attr('data-type','form-submission')
        $('.rate-wrap .sitetitle').show()
        $('.rate-wrap .overalltitle').hide()
        jumpto(-1)
    }
    return false
})
window.onload = function() {
    inactivityTime();
}

$("#inactivitydailog").on('keydown',function(e){
    if(e.key == "Escape"){
        return false
    }
})

$(".smile-wrap svg").on('click',function(){
    $(".smile-wrap .floatingsmiley").show()
    const animationto = $(this).data('path')
    const color = $(this).data('color')
    const score = $(this).data('score')
    const type = $(this).data('type')
    const uid = $(this).data('uid')
    $(".smile-wrap .floatingsmiley .fill").css('fill',`${color}`)
    $(".smile-wrap .floatingsmiley .lips").css('d',`"${animationto}"`)
    $(".smile-wrap .floatingsmiley .lips").css('d',`path("${animationto}")`)
    $(".smile-wrap .floatingsmiley").css('left',$(this).position().left - $(".smile-wrap").offset().left)
    let submission = false
    if(type){
        submission = thankyousave(uid,score)
    } else {
        submission = overallsave(score)
    }
    if(submission){
        setTimeout(() => {
            document.getElementById("thankyoudialog").showModal()
        }, 1000)
        clearTimeout(reloadtime)
        reloadtime = setTimeout(function() {
            location.reload(true)
        },5000)
    }
    return false
})

// $(".overallsubmit").on('click',function(){
//     const score = $(this).data('overallscore')
//     const submitoverall = overallsave(score)
//     if(submitoverall){
//         document.getElementById("thankyoudialog").showModal()
//         clearTimeout(reloadoveralltime)
//         reloadoveralltime = setTimeout(function() {
//             // console.log("Time to Refresh")
//             location.reload(true)
//         },5000)
//     }
// })