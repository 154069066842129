import Papa from 'papaparse';

export const getcsv = () => {
    downloadcsv = () => {
        fetch("https://ap-south-1.aws.data.mongodb-api.com/app/application-0-bvbuf/endpoint/getcsv")
        .then( response => response.json() )
        .then( response => {
            console.log(response)
            const data = response.result.map(el => {
                return {
                    Entity:el.entity,
                    Name: el.name,
                    Email: el.email,
                    Phone: el.phone,
                    "Additional comments":el.comments,
                    "Content & Design":el.content,
                    Innovativeness:el.innovative,
                    "Community Value":el.community,
                    "Thank You Rating":el.score
                }
            })
            const csv = Papa.unparse(data,
                {
                    header: true,
                    quotes: false,
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ",",
                    newline: "\r\n",
                    skipEmptyLines: false,
                    columns: null
                }
            )
            const link = document.createElement('a')
            const file = new Blob([csv], {type: 'text/csv'});
            link.setAttribute("href", URL.createObjectURL(file));
            link.setAttribute("download", "response.csv");
            document.body.appendChild(link);
            link.click()
            link.remove()
        })
    }
}