import $ from "jquery"
import {thankyousave} from "./thanyousave"

export const smileyanim = () => {
    let reloadtime
    $(".feedbacksmiley li").on('click',function(){
        const animationto = $(this).data('path')
        const color = $(this).data('color')
        const score = $(this).data('score')
        const uid = $(this).data('uid')
        // $(".floatingsmiley").animate({width:"20px"},175,function() {
        //     $(".floatingsmiley").animate({width:"220px"},175)
        // })
        // $(".floatingsmiley").addClass("active")
        // setTimeout(() => {
        //     $(".floatingsmiley").removeClass("active")
        // },350)
        $(".floatingsmiley .fill").css('fill',`${color}`)
        $(".floatingsmiley .lips").css('d',`"${animationto}"`)
        $(".floatingsmiley .lips").css('d',`path("${animationto}")`)
        // $(".floatingsmiley").css('animation','none')
        // $(".floatingsmiley").css('animation','scaleDown 0.35s ease 0s 1 forward')
        $(".floatingsmiley").css('left',$(this).position().left)
        const submitthankyou = thankyousave(uid,score)
        if(submitthankyou){
            clearTimeout(reloadtime)
            reloadtime = setTimeout(function() {
                // console.log("Time to Refresh")
                location.reload(true)
            },3000)
        }
        return false
    })
}