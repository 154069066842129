import { savetomongo, savetomongooverall } from "./ajax";
import { getcurrentindex } from "./slide";
import $ from "jquery"

export const inactivityTime = function () {
    let inactivitytimereset
    let notimer
    let nointerval
    let time = 10
    function logout() {
        savetomongo()
        savetomongooverall()
        const crntindex = getcurrentindex()
        if(crntindex !== 0){
            document.getElementById("inactivitydailog").showModal()
            clearTimeout(notimer);
            clearInterval(nointerval)
            nointerval = setInterval(() => {
                time > 0 ? time-- : time
                $("#inactivityno").html(`<div class="btn"><div class="txt">No <span class="ar-">لا</span> <i class="time">(${time}s)</i></div></div>`)
            },1000)
            notimer = setTimeout(() => {
                $("#inactivityno").trigger('click')
            },11000)
        }
    }

    const resetTimer = () => {
        clearTimeout(inactivitytimereset);
        inactivitytimereset = setTimeout(logout, 120000)
    }

    const events = ['mousemove','touchmove','click','keydown']
    events.forEach(function(e){
        window.addEventListener(e, resetTimer, false)
    })

    $("#inactivityyes").on('click',function(){
        clearTimeout(notimer);
        clearInterval(nointerval)
        time = 10
        document.getElementById("inactivitydailog").close()
    })
    $("#inactivityno").on('click',function(){
        location.reload(true)
    })

    resetTimer()
};