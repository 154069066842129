import $ from 'jquery'

let imageindex = 0
const pushnext = () => {
    imageindex++
    slide()
}

const pushprev = () => {
    imageindex--
    slide()
}

const jumpto = (n) => {
    imageindex = n
    slide()
}

const slide = () => {
    $(".slide").each(function(){
        if(imageindex < 0){
            imageindex = $(".slide").length + imageindex
        }
        const offset = imageindex === 0 ? 0 : 40
        $(this).css('transform',`translate(calc(${-100 * imageindex}% - ${offset * imageindex}px))`)
    })
}

const getcurrentindex = () => {
    return imageindex
}

export {
    pushnext,
    pushprev,
    jumpto,
    getcurrentindex
}