let conttroller = new AbortController()

const savetomongo = () => {
    const entries = localStorage.getItem('entries')
    const thankyou = localStorage.getItem('thankyourating')
    const syncData = entries.split("|").map(el => {
        const thankyouentry = thankyou.split("|")
        let data = {...JSON.parse(el)}
        const spicificThankyou = thankyouentry.find(els => els.uid === el.uid)
        if(spicificThankyou){
            data = {...data,...JSON.parse(spicificThankyou)}
        }
        return data
    })
    // console.log(syncData)
    fetch("https://ap-south-1.aws.data.mongodb-api.com/app/application-0-bvbuf/endpoint/sync",{
        method: 'POST',
        body: JSON.stringify(syncData),
        signal: conttroller.signal
    })
    .then( response => response.json() )
    .then( response => {
        if(response.result?.insertedIds){
            localStorage.removeItem('entries')
            localStorage.removeItem('thankyourating')
        }
    })
}
const savetomongooverall = () => {
    const overallrating = localStorage.getItem('overallrating')
    const syncData = overallrating.split("|").map(el => {
        return JSON.parse(el)
    })
    fetch("https://ap-south-1.aws.data.mongodb-api.com/app/application-0-bvbuf/endpoint/syncoverall",{
        method: 'POST',
        body: JSON.stringify(syncData),
        signal: conttroller.signal
    })
    .then( response => response.json() )
    .then( response => {
        if(response.result?.insertedIds){
            localStorage.removeItem('overallrating')
        }
    })
}

export {
    savetomongo,
    savetomongooverall
}