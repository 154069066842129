import { savetomongo } from "./ajax"

export const thankyousave = (uid,score) => {
    // console.log(uid)
    // console.log(score)
    const current = localStorage.getItem('thankyourating')
    if(current){
        const crntData = current.split("|")
        const modifyData = [...crntData, JSON.stringify({score:score,uid:uid})]
        const string = modifyData.join('|')
        localStorage.setItem('thankyourating',string)
        savetomongo()
        return true
    } else {
        const entryData = JSON.stringify({score:score,uid:uid})
        const modifyData = []
        modifyData.push(entryData)
        const string = modifyData.join('|')
        localStorage.setItem('thankyourating',string)
        savetomongo()
        return true
    }
}