import { savetomongooverall } from "./ajax"

export const overallsave = (score) => {
    console.log(score)
    const current = localStorage.getItem('overallrating')
    if(current){
        const crntData = current.split("|")
        const modifyData = [...crntData, JSON.stringify({score})]
        const string = modifyData.join('|')
        localStorage.setItem('overallrating',string)
        savetomongooverall()
        return true
    } else {
        const entryData = JSON.stringify({score})
        const modifyData = []
        modifyData.push(entryData)
        const string = modifyData.join('|')
        localStorage.setItem('overallrating',string)
        savetomongooverall()
        return true
    }
}