import { v4 as uuid } from 'uuid';

export const save = (entry) => {
    const current = localStorage.getItem('entries')
    if(current){
        const crntData = current.split("|")
        const uid = uuid()
        const modifyData = [...crntData, JSON.stringify({...entry,uid:uid})]
        const string = modifyData.join('|')
        localStorage.setItem('entries',string)
        return uid
    } else {
        const uid = uuid()
        const entryData = JSON.stringify({...entry,uid:uid})
        const modifyData = []
        modifyData.push(entryData)
        const string = modifyData.join('|')
        localStorage.setItem('entries',string)
        return uid
    }
}